.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  max-width:370px;
}

button {
  margin: 0 auto;
  padding: 5px 8px;
  cursor: pointer;
  margin-top:8px;
  width:80px;
  float: left;
  border:0px;
  background-color:#444;
  color:#fff;
}

.modal-content p {font-weight: bold; font-size:20px; }